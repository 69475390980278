/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import useStyles from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Skeleton from '@material-ui/lab/Skeleton';

import Router from 'next/router';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const ExpandedMenu = ({ type }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSkeleton, setShowSkeleton] = React.useState(true);

  const rootRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = () => {
    setAnchorEl(rootRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const widgetsCategory = [
    {
      name: 'Instagram',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624588223/Woxo/Videos_Widgets/Widgets/ig-carousel.png',
      categories: [
        {
          name: 'Carousels',
          value: 'carousel'
        },
        {
          name: 'Grids',
          value: 'grid'
        },
        {
          name: 'Hero',
          value: 'hero'
        },
        {
          name: 'Small Businesses',
          value: 'small-business'
        },
        {
          name: 'Bloggers',
          value: 'bloggers'
        },
        {
          name: 'Non-profits',
          value: 'non-profits'
        },
        {
          name: 'Influencers',
          value: 'influencers'
        },
        {
          name: 'See all',
          value: 'See all'
        }
      ]
    },
    {
      name: 'TikTok',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1632148897/Woxo/Videos_Widgets/Widgets/tk-grid-1.png',
      categories: [
        {
          name: 'Carousels',
          value: 'carousel'
        },
        {
          name: 'Grids',
          value: 'grid'
        },
        {
          name: 'See all',
          value: 'See all'
        }
      ]
    },
    {
      name: 'YouTube',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624588223/Woxo/Videos_Widgets/Widgets/yt-carousel.png',
      categories: [
        {
          name: 'Carousels',
          value: 'carousel'
        },
        {
          name: 'Grids',
          value: 'grid'
        },
        {
          name: 'Music',
          value: 'music'
        },
        {
          name: 'Sports',
          value: 'sports'
        },
        {
          name: 'News',
          value: 'news'
        },
        {
          name: 'Popular',
          value: 'popular'
        },
        {
          name: 'See all',
          value: 'See all'
        }
      ]
    },
    {
      name: 'Twitter',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624588223/Woxo/Videos_Widgets/Widgets/tw-carousel.png',
      categories: [
        {
          name: 'Carousels',
          value: 'carousel'
        },
        {
          name: 'Grids',
          value: 'grid'
        },
        {
          name: 'Small Businesses',
          value: 'small-business'
        },
        {
          name: 'Bloggers',
          value: 'bloggers'
        },
        {
          name: 'Non-profits',
          value: 'non-profits'
        },
        {
          name: 'Influencers',
          value: 'influencers'
        },
        {
          name: 'See all',
          value: 'See all'
        }
      ]
    },
    {
      name: 'Facebook',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624651068/Woxo/Videos_Widgets/Widgets/Facebook/fc-carousel.png',
      categories: [
        {
          name: 'Carousel',
          value: 'carousel',
          image:
            'https://res.cloudinary.com/dakp804eh/image/upload/v1624650024/Woxo/Videos_Widgets/Widgets/Facebook/fc-carousel.png'
        },
        {
          name: 'Grid',
          value: 'grid',
          image:
            'https://res.cloudinary.com/dakp804eh/image/upload/v1624650024/Woxo/Videos_Widgets/Widgets/Facebook/fc-grid.png'
        },
        // {
        //   name: 'Masonry',
        //   value: 'masonry',
        //   image:
        //     'https://res.cloudinary.com/dakp804eh/image/upload/v1624650024/Woxo/Videos_Widgets/Widgets/Facebook/fc-masonry.png'
        // },
        // {
        //   name: 'SlideShow',
        //   value: 'slideShow',
        //   image:
        //     'https://res.cloudinary.com/dakp804eh/image/upload/v1624650024/Woxo/Videos_Widgets/Widgets/Facebook/fc-slideshow.png'
        // },
        {
          name: 'See all',
          value: 'See all'
        }
      ]
    }
  ];

  const videoCategories = [
    {
      name: 'Entertainment',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-ingo-joseph-1755087.jpg'
    },
    {
      name: 'Greetings',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-giftpunditscom-2072162.jpg'
    },
    {
      name: 'Pets',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624587324/Woxo/Videos_Widgets/Videos/pexels-koolshooters-7683642.jpg'
    },
    {
      name: 'Invites to Special Events',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626325674/Woxo/Videos_Widgets/Videos/pexels-tara-winstead-6479559.jpg'
    },
    {
      name: 'Quotes by Famous People',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626325973/Woxo/Videos_Widgets/Videos/pexels-bich-tran-636243.jpg'
    },
    {
      name: 'Fun Facts',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-pixabay-207983.jpg'
    },
    {
      name: 'Trivia',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-olya-kobruseva-5428836.jpg'
    },
    {
      name: 'Things to do',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-cottonbro-3951628.jpg'
    },
    {
      name: 'Food',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624587324/Woxo/Videos_Widgets/Videos/pexels-sebastian-coman-photography-3655916.jpg'
    },
    {
      name: 'Jokes',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-ilargian-faus-1629781.jpg'
    },
    {
      name: 'Travel',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-pierre-blache%CC%81-2901209.jpg'
    },
    {
      name: 'Tips',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-ann-h-2789779.jpg'
    },
    {
      name: 'Reviews',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626326362/Woxo/Videos_Widgets/Videos/pexels-rodnae-productions-7563652.jpg'
    },
    {
      name: 'Email Marketing',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-torsten-dettlaff-193003.jpg'
    },
    {
      name: 'Restaurants',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-the-castlebar-5914437.jpg'
    },
    {
      name: 'E-commerce',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624587324/Woxo/Videos_Widgets/Videos/pexels-oleg-magni-2285326.jpg'
    },
    {
      name: 'Hotels',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626152651/Woxo/Videos_Widgets/Videos/pexels-thorsten-technoman-338504.jpg'
    },
    {
      name: 'Quotes',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1624587324/Woxo/Videos_Widgets/Videos/pexels-jane-pham-1571673.jpg'
    },
    {
      name: 'See All',
      image:
        'https://res.cloudinary.com/dakp804eh/image/upload/v1626154911/Woxo/Videos_Widgets/Videos/pexels-pixabay-163822.jpg'
    }
  ];

  const handleVideoMenuItemClick = n => {
    const category = n === 'See All' ? '' : n;
    Router.push(`/video/templates?category=${category}`);
  };
  const handleWidgetMenuItemClick = (n, t) => {
    let pathnameForRouter = '/insta-wdgt';
    let pathnameForLocation = '/instagram-widget';
    switch (n) {
      case 'TikTok': {
        // Hack for next and the rewrites needed for the AddBlock Plus
        pathnameForRouter = '/tkk-wdgt';
        pathnameForLocation = '/tiktok-widget';
        break;
      }
      case 'Twitter': {
        // Hack for next and the rewrites needed for the AddBlock Plus
        pathnameForRouter = '/twter-wdgt';
        pathnameForLocation = '/twitter-widget';
        break;
      }
      case 'YouTube': {
        // Hack for next and the rewrites needed for the AddBlock Plus
        pathnameForRouter = '/ytbe-wdgt';
        pathnameForLocation = '/youtube-widget';
        break;
      }
      case 'Facebook': {
        // Hack for next and the rewrites needed for the AddBlock Plus
        pathnameForRouter = '/fcb-wdgt';
        pathnameForLocation = '/facebook-widget';
        break;
      }
      default: {
        // Hack for next and the rewrites needed for the AddBlock Plus
        pathnameForRouter = '/insta-wdgt';
        pathnameForLocation = '/instagram-widget';
        break;
      }
    }

    if (t.name !== 'See all') {
      pathnameForRouter = `${pathnameForRouter}?cat=${t.value}`;
      pathnameForLocation = `${pathnameForLocation}?cat=${t.value}`;
    }

    // Hack for next and the rewrites needed for the AddBlock Plus
    // Router.push(pathnameForRouter);
    window.location.href = window.location.origin + pathnameForLocation;
  };

  React.useEffect(() => {
    if (type === 'widget') {
      setItems(widgetsCategory);
      setTimeout(() => {
        setShowSkeleton(false);
      }, 100);
    } else {
      setItems(videoCategories);
      setTimeout(() => {
        setShowSkeleton(false);
      }, 100);
    }
  }, []);

  const WidgetsMenu = ({ items }) => {
    return (
      <>
        {items.map((v, i) => (
          <Box key={i} className={classes.templateContent}>
            <Box className={classes.templateImgBox}>
              <img src={v.image} alt="" />
            </Box>
            <Typography className={classes.templateName}>{v.name}</Typography>
            {v.categories.map(c => (
              <Box
                key={i}
                className={classes.categoriesBox}
                onClick={() => {
                  handleWidgetMenuItemClick(v.name, c);
                }}
              >
                <Typography className={classes.categoryName}>{c.name}</Typography>
              </Box>
            ))}
          </Box>
        ))}
      </>
    );
  };

  const VideosMenu = ({ items }) => {
    return (
      <>
        {items.map((v, i) => (
          <VideoMenuItem key={i} v={v} />
        ))}
      </>
    );
  };

  const VideoMenuItem = ({ v }) => {
    return (
      <Box
        className={clsx(classes.templateContent, 'videoContent')}
        onClick={() => {
          handleVideoMenuItemClick(v.name);
        }}
      >
        <Box className={classes.templateImgBoxVideo}>
          <img src={v.image} alt="image" />
        </Box>
        <Box className={classes.categoriesBox}>
          <Typography
            className={classes.categoryName}
            style={{
              fontWeight: v.name === 'See All' ? 'bolder' : 'normal',
              textDecorationLine: v.name === 'See All' ? 'underline' : 'none'
            }}
          >
            {v.name}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <a ref={rootRef} onClick={handlePopoverOpen} className={classes.menuLink}>
        {type === 'video' ? 'Video Templates' : 'Widgets'}
        {open ? (
          <KeyboardArrowUpIcon style={{ color: '#414042' }} />
        ) : (
          <KeyboardArrowDownIcon style={{ color: '#414042' }} />
        )}
      </a>
      <Popover
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: type === 'video' ? classes.paperVideo : classes.paperWidget
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={clsx(classes.popper)}
      >
        <Box className={classes.popoverContent}>
          <div className={classes.btnCloseBox}>
            <IconButton onClick={handlePopoverClose} className={classes.closeIcon}>
              <ClearIcon />
            </IconButton>
          </div>
          {showSkeleton ? (
            <>
              {[1, 2, 3, 4].map(v => (
                <Box key={v} className={classes.templateContent}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={160}
                    style={{ borderRadius: '8px' }}
                  />
                </Box>
              ))}
            </>
          ) : type === 'video' ? (
            <VideosMenu items={items} />
          ) : (
            <WidgetsMenu items={items} />
          )}
        </Box>
      </Popover>
    </>
  );
};

ExpandedMenu.propTypes = {
  type: PropTypes.string
};

ExpandedMenu.defaultProps = {
  type: 'video'
};

export default ExpandedMenu;
