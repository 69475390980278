import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Link, Typography } from '@material-ui/core';

function ErrorAlert() {
  return (
    <Box
      role="alert"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Typography>
        ❌ Sorry, an error has ocurred.
        <Link href={window.location.href}>Try reloading the page.</Link>
      </Typography>
    </Box>
  );
}

export default function ErrorAlertBoundary(props) {
  return <ErrorBoundary FallbackComponent={ErrorAlert} {...props} />;
}
