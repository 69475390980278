import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Grid, Typography, Container } from '@material-ui/core';
import { LinkedinIcon, TwitterIcon, YoutubeIcon, FacebookFooterIcon, InstagramIcon } from '../Icon';
import Link from 'next/link';

import PostsList from '../PostsList';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#3d3d3d',
    color: '#fff',
    position: 'relative'
  },
  footerContainer: {
    paddingTop: '90px',
    paddingBottom: '45px'
  },
  smallFooterContainer: {
    paddingTop: '50px',
    paddingBottom: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '@media only screen and (max-width: 650px)': {
      flexDirection: 'column'
    }
  },
  iconsContainers: {
    marginBottom: 40
  },
  copyright: {
    fontSize: 16,
    fontWeight: '300',
    color: '#fff',
    marginTop: 40,
    '@media only screen and (max-width: 425px)': {
      textAlign: 'center'
    }
  },
  iconTwitter: {
    fontSize: 42,
    marginRight: 24,
    '@media only screen and (max-width: 425px)': {
      marginRight: 12
    }
  },
  iconLinkedin: {
    fontSize: 42
  },
  linkTitle: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 800
  },
  linksContainer: {
    justifyContent: 'space-between'
  },
  link: {
    fontSize: 16,
    fontWeight: '300',
    margin: '12px 0px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '@media only screen and (max-width: 425px)': {
      textAlign: 'center'
    }
  },
  grid: {
    '@media only screen and (max-width: 425px)': {
      marginBottom: 40,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  iconsRow: {
    '@media only screen and (max-width: 425px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

const Footer = ({ className, showSmallFooter }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      {showSmallFooter ? (
        <Container className={classes.smallFooterContainer}>
          <Box className={classes.copyright} style={{ marginTop: 0 }}>
            &copy;
            {` ${new Date().getFullYear()} `}
            All Rights Reserved, WOXO
          </Box>
          <Box>
            <Typography className={classes.link} style={{ marginLeft: 50, marginRight: 50 }}>
              <Link href="/legal/terms-of-service">
                <a>Terms of Service</a>
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.link}>
              <Link href="/legal/privacy-policy">
                <a>Privacy Policy</a>
              </Link>
            </Typography>
          </Box>
        </Container>
      ) : (
        <Container className={classes.footerContainer}>
          <Grid container>
            <Grid item xs={12} lg={4} xl={4} className={classes.iconsContainers}>
              <Box className={classes.iconsRow}>
                <a href="https://www.facebook.com/woxotech/" target="_blank" rel="noreferrer">
                  <FacebookFooterIcon
                    className={classes.iconTwitter}
                    width="42.569"
                    height="49.155"
                    viewBox="0 0 42.569 49.155"
                  />
                </a>
                <a href="https://www.instagram.com/woxo.tech/" target="_blank" rel="noreferrer">
                  <InstagramIcon
                    className={classes.iconTwitter}
                    width="42.569"
                    height="49.155"
                    viewBox="0 0 42.569 49.155"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/front10/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedinIcon
                    className={classes.iconTwitter}
                    width="42.569"
                    height="49.155"
                    viewBox="0 0 42.569 49.155"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCUjYDLLKFfBbTbaiBSFT3_A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YoutubeIcon
                    className={classes.iconTwitter}
                    width="42.569"
                    height="49.155"
                    viewBox="0 0 42.569 49.155"
                  />
                </a>
                {/* <a href="https://twitter.com/front10hello">
                  <TwitterIcon
                    className={classes.iconLinkedin}
                    width="42.569"
                    height="49.155"
                    viewBox="0 0 42.569 49.155"
                  />
                </a> */}
              </Box>
            </Grid>
            <Grid item xs={12} lg={8} xl={8}>
              <Grid container className={classes.linksContainer}>
                <Grid item className={classes.grid} xs={12} sm={3}>
                  <Typography className={classes.linkTitle}>Resources</Typography>
                  <Typography className={classes.link}>
                    <a href="https://support.woxo.tech/en/" target="_blank" rel="noreferrer">
                      Support Center
                    </a>
                  </Typography>
                  <Typography className={classes.link}>
                    <a href="https://woxo.tech/blog/" target="_blank" rel="noreferrer">
                      Blog
                    </a>
                  </Typography>
                  {/* <Typography className={classes.link}>
                    <a href="/tutorials">Tutorials</a>
                  </Typography> */}
                  <Typography className={classes.link}>
                    <a
                      href="https://www.notion.so/Woxo-s-Roadmap-8804e18c43a04f8ca29a141621238f24"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Roadmap
                    </a>
                  </Typography>
                </Grid>
                <Grid item className={classes.grid} xs={12} sm={3}>
                  <Typography className={classes.linkTitle}>Company</Typography>
                  <Typography className={classes.link}>
                    <a href="https://front10.com" target="_blank" rel="noreferrer">
                      Home
                    </a>
                  </Typography>
                  <Typography className={classes.link}>
                    <a href="https://front10.com/about.html" target="_blank" rel="noreferrer">
                      About
                    </a>
                  </Typography>
                  <Typography className={classes.link}>
                    <a href="https://front10.com/careers.html" target="_blank" rel="noreferrer">
                      Careers
                    </a>
                  </Typography>
                  <Typography className={classes.link}>
                    <a href="https://front10.com/contact.html" target="_blank" rel="noreferrer">
                      Contact Us
                    </a>
                  </Typography>
                </Grid>
                <Grid item className={classes.grid} xs={12} sm={3}>
                  <Typography className={classes.linkTitle}>Legal</Typography>
                  <Typography className={classes.link}>
                    <Link href="/legal/privacy-policy">
                      <a>Privacy Policy</a>
                    </Link>
                  </Typography>
                  <Typography className={classes.link}>
                    <Link href="/legal/terms-of-service">
                      <a>Terms of Service</a>
                    </Link>
                  </Typography>
                  <Typography className={classes.link}>
                    <Link href="/legal/trademark-policy">
                      <a>Trademark Policy</a>
                    </Link>
                  </Typography>
                  <Typography className={classes.link}>
                    <Link href="/legal/sla">
                      <a>SLA</a>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item className={classes.grid} xs={12} sm={3}>
                  <PostsList />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.copyright}>
            &copy;
            {` ${new Date().getFullYear()} `}
            All Rights Reserved, WOXO
          </Box>
        </Container>
      )}
    </Box>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  showSmallFooter: PropTypes.bool
};

Footer.defaultProps = {
  className: null,
  showSmallFooter: false
};

export default Footer;
