/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import clsx from 'clsx';
import { Box, Button, MenuItem, MenuList } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './styles';
import Popover from '../Popover';
import { useAuthentication } from '../../hooks/useAuthentication';
import useAppEvent from '../../hooks/useAppEvent';
import { remove } from '../../services/notification.service';

dayjs.extend(relativeTime);

const UserNotification = ({ className, containerClassName }) => {
  const classes = useStyles();
  const handleNotification = useAppEvent();
  const [open, setOpen] = React.useState(false);
  const {
    notifications: { data, count },
    dispatch
  } = useAuthentication();

  React.useEffect(() => {
    if (count === 0) setOpen(false);
  }, [count]);

  const handleClick = event => {
    if (data.length) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = (e, n) => {
    e.stopPropagation();
    remove(n).then(() => {
      dispatch({
        type: 'REMOVE_NOTIFICATIONS',
        notification: n._id
      });
    });
  };

  if (!data.length) {
    return null;
  }

  return (
    <Box position="relative" className={clsx(containerClassName, 'notification-container')}>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        content={
          <Box className={classes.listPopoverContainer}>
            <MenuList id="menu-list-grow" className={classes.list}>
              {data.map(n => (
                <MenuItem
                  key={n._id}
                  className={classes.menuItem}
                  onClick={() => {
                    handleClose();
                    handleNotification(n);
                  }}
                >
                  <FiberManualRecordIcon className="dot" />
                  <Box>
                    <div dangerouslySetInnerHTML={{ __html: n.message }} />
                    <Box className={classes.date}>
                      {dayjs().locale('en').to(dayjs(n.createdAt))}
                    </Box>
                  </Box>
                  <DeleteIcon
                    className="trash"
                    onClick={e => {
                      handleRemove(e, n);
                    }}
                  />
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        }
      >
        <Button
          color="inherit"
          onClick={handleClick}
          className={clsx(classes.buttonPopover, data.length ? 'primary' : '', className)}
        >
          {!!data.length && <NotificationsActiveIcon />}
        </Button>
      </Popover>
      {!!data.length && <span className={classes.badge}>{count}</span>}
    </Box>
  );
};

UserNotification.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string
};

UserNotification.defaultProps = {
  className: null,
  containerClassName: null
};

export default UserNotification;
