import { makeStyles } from '@material-ui/core/styles';
import { Opacity } from '@material-ui/icons';

export default makeStyles(theme => {
  const color = theme.palette.background.paper;
  return {
    paperWidget: {
      borderRadius: 8,
      boxShadow:
        '0 5px 10px 0 rgba(0, 0, 0, 0.1),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
      '@media only screen and (max-width: 960px)': {
        top: '0!important',
        left: '0!important',
        minWidth: '100vw',
        minHeight: '100vh'
      }
    },
    paperVideo: {
      borderRadius: 8,
      maxWidth: 1092,
      boxShadow:
        '0 5px 10px 0 rgba(0, 0, 0, 0.1),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
      '@media only screen and (max-width: 960px)': {
        top: '0!important',
        left: '0!important',
        minWidth: '100vw',
        minHeight: '100vh'
      }
    },
    popoverContent: {
      width: '100%',
      minHeight: 200,
      display: 'flex',
      flexWrap: 'wrap',
      padding: 20,
      '@media only screen and (max-width: 960px)': {
        padding: 40,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
      }
    },
    popper: {
      marginTop: 12,
      '@media only screen and (max-width: 960px)': {
        marginTop: 0
      }
    },
    activeLink: {
      color: '#0086FF',
      '&:before': {
        backgroundColor: '#0086FF'
      },
      '&:after': {
        backgroundColor: '#0086FF'
      }
    },
    menuLink: {
      cursor: 'pointer',
      fontSize: 14,
      position: 'relative',
      letterSpacing: '1.4px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '19px',
      color: '#414042',
      textDecoration: 'none',
      // textTransform: 'uppercase',
      marginRight: '5%',
      transition: 'all 0.2s ease-in-out',
      '@media only screen and (max-width: 1024px)': {
        marginRight: '3%'
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        bottom: '-10px',
        width: 0,
        height: '1px',
        margin: '5px 0 0',
        opacity: 0,
        backgroundColor: '#414042',
        transition: 'all 0.2s ease-in-out',
        transitionDuration: '0.35s',
        left: '50%'
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: '-10px',
        width: 0,
        height: '1px',
        margin: '5px 0 0',
        opacity: 0,
        backgroundColor: '#414042',
        transition: 'all 0.2s ease-in-out',
        transitionDuration: '0.35s',
        right: '50%'
      },
      '&:hover:before': {
        left: '50%',
        width: '50%',
        opacity: 1
      },
      '&:hover:after': {
        right: '50%',
        width: '50%',
        opacity: 1
      },
      '& svg': {
        position: 'relative',
        top: 2
      },
      '@media only screen and (max-width: 960px)': {
        marginBottom: 30,
        marginRight: 0
      }
    },
    templateContent: {
      width: 180,
      '&.videoContent': {
        width: 135,
        margin: '7px!important',
        padding: '5px',
        transition: 'all .5s',
        borderRadius: 8,
        '&:hover': {
          cursor: 'pointer',
          background: '#f2f2f2'
        },
        '& p': {
          width: 115,
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      },
      '&:not(:last-child)': {
        marginRight: 20
      },
      '@media only screen and (max-width: 960px)': {
        '&:not(:last-child)': {
          marginRight: 0,
          marginBottom: 40
        }
      }
    },
    templateImgBox: {
      width: '100%',
      height: 100,
      background: '#E9E9E9',
      borderRadius: 8,
      marginBottom: 10,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    },
    templateImgBoxVideo: {
      width: '100%',
      height: 100,
      background: '#E9E9E9',
      borderRadius: 8,
      overflow: 'hidden',
      '@media only screen and (max-width: 960px)': {
        display: 'none'
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    },
    templateName: {
      padding: '7px 5px',
      fontSize: 14,
      lineHeight: '19px',
      fontWeight: 700,
      color: '#414042',
      '@media only screen and (max-width: 960px)': {
        textAlign: 'center'
      }
    },
    categoriesBox: {
      padding: '7px 5px',
      transition: 'all .5s',
      borderRadius: 6,
      '&:hover': {
        background: '#f2f2f2',
        cursor: 'pointer'
      },
      '@media only screen and (max-width: 960px)': {
        textAlign: 'center'
      }
    },
    categoryName: {
      fontSize: 14,
      lineHeight: '19px',
      color: '#414042'
    },
    btnCloseBox: {
      display: 'none',
      justifyContent: 'space-between',
      padding: '12px 20px',
      position: 'fixed',
      top: 0,
      right: 0,
      '@media only screen and (max-width: 960px)': {
        display: 'flex'
      }
    },
    closeIcon: {
      color: '#3399FF',
      height: '50px',
      width: '50px',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#0060BF'
      }
    }
  };
});
