import event from '../helpers/event';
import { isFunction } from '../helpers/javascript';

const useAppEvent = () => {
  return notification => {
    if (event[notification.event] && isFunction(event[notification.event]))
      event[notification.event](notification.meta);
  };
};

export default useAppEvent;
