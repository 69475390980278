import Router from 'next/router';

export default {
  'on-woxo-edit-widget': params => {
    if (new RegExp('/browse/[a-z0-1][a-z0-1]*').test(window.location.pathname)) {
      if (window.location.pathname !== `/browse/${params.code}`)
        window.location.href = `${window.location.origin}/browse/${params.code}?popup=feed`;
    } else Router.push(`/browse/${params.code}?popup=feed`);
  }
};
